@import '../pdGlobal/PdGlobal';

.main-content-container {
    position: relative;
    display: flex;
    flex-direction: column;

    .cta-text,
    .heading-text {
        padding: 0 .75rem;
        margin: 0 2.56rem;

        .heading,
        .heading-image-component {
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;

            @include media-breakpoint-up(md) {
                margin-top: 0;
                margin-bottom: 2rem;
            }
        }

        .heading-line-1 {
            margin-bottom: 0.5rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 1rem;
            }
        }

        .subheading-image-component {
            margin-bottom: 2rem;
        }

        &.bottom-left {
            justify-content: flex-start;
            text-align: left;
            align-items: flex-end;
            order: 2;
        }

        &.bottom-center {
            justify-content: center;
            text-align: center;
            align-items: flex-end;
            order: 2;
        }

        &.bottom-right {
            justify-content: flex-end;
            text-align: right;
            align-items: flex-end;
            order: 2;
        }

        &.center-left {
            justify-content: flex-start;
            text-align: left;
            align-items: center;
        }

        &.center-center {
            justify-content: center;
            text-align: center;
            align-items: center;
        }

        &.center-right {
            justify-content: flex-end;
            text-align: right;
            align-items: center;
        }

        &.top-left {
            justify-content: flex-start;
            text-align: left;
            align-items: flex-start;
        }

        &.top-center {
            justify-content: center;
            text-align: center;
            align-items: flex-start;
        }

        &.top-right {
            justify-content: flex-end;
            text-align: right;
            align-items: flex-start;
        }

        &.font-primary {
            font-family: $font-primary;
        }

        &.font-medium {
            font-family: $font-medium;
        }

        &.font-demi {
            font-family: $font-demi;
        }
    }

    @include media-breakpoint-down(md) {

        .cta-text,
        .heading-text {
            display: inline;

            &.mobile-bottom-left {
                justify-content: flex-start;
                text-align: left;
                align-items: flex-end;
                order: 2;
            }

            &.mobile-bottom-center {
                justify-content: center;
                text-align: center;
                align-items: flex-end;
                order: 2;
            }

            &.mobile-bottom-right {
                justify-content: flex-end;
                text-align: right;
                align-items: flex-end;
                order: 2;
            }

            &.mobile-center-left {
                justify-content: flex-start;
                text-align: left;
                align-items: center;
            }

            &.mobile-center-center {
                justify-content: center;
                text-align: center;
                align-items: center;
            }

            &.mobile-center-right {
                justify-content: flex-end;
                text-align: right;
                align-items: center;
            }

            &.mobile-top-left {
                justify-content: flex-start;
                text-align: left;
                align-items: flex-start;
            }

            &.mobile-top-center {
                justify-content: center;
                text-align: center;
                align-items: flex-start;
            }

            &.mobile-top-right {
                justify-content: flex-end;
                text-align: right;
                align-items: flex-start;
            }
        }
    }

    .cta-text {
        margin-bottom: rem(36);
        letter-spacing: 0.08em;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        text-underline-offset: rem(2);
    }

}

.wide-image-container {
    .noBleed {
        height: auto;
        padding: 0;
        width: 100%;

        .image-component {
            padding: 0 rem(12) 0 rem(12);

            @include media-breakpoint-up(md) {
                padding: 0 rem(32);
            }

            @include media-breakpoint-up(lg) {
                padding: 0 rem(80);
            }
        }
    }

    .fullBleed {
        padding: 0;
        margin: 0;
    }

    .heading-text {
        .description {
            .subheading {
                margin-bottom: 1.5rem;
                max-width: rem(1064);

                @include media-breakpoint-up(md) {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .rich-text-content {
        margin-top: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

.pd-no-navigation-panel {
    padding: 0;
}